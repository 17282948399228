<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-title><h3>Einstellungen</h3></v-card-title>
    <v-card-text>
      <validation-errors :errors="validationErrors" v-if="validationErrors" />
      <v-layout>
        <v-flex xs6>
          <v-select
            :items="companySettings"
            @change="settingsId = null"
            item-key="name"
            label="Firma"
            v-model="companyId"
          />
        </v-flex>
        <v-flex xs6>
          <v-select
            :items="possibleSettings"
            item-key="name"
            label="Einstellung"
            v-model="settingsId"
          />
        </v-flex>
      </v-layout>
    </v-card-text>

    <template v-if="settingData">
      <v-card-text>
        <template v-if="structureMap">
          <v-layout row wrap>
            <v-flex xs12 v-for="(struct, key) in structureMap" :key="key" mb-4>
              <template v-if="struct.type === 'toggle'">
                <v-layout row wrap>
                  <v-flex :key="key" xs12>
                    <v-switch
                      :label="struct.label"
                      v-model="settingData[struct.name]"
                    />
                  </v-flex>
                </v-layout>
              </template>

              <template v-if="struct.type === 'text'">
                <v-layout row wrap>
                  <v-flex :key="key" xs12>
                    <v-text-field
                      :label="struct.label"
                      v-model="settingData[struct.name]"
                    />
                  </v-flex>
                </v-layout>
              </template>

              <template v-if="struct.type === 'keyValue'">
                <v-expansion-panel>
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <h2>{{ struct.label }}</h2>
                    </template>
                    <v-layout row wrap>
                      <v-flex
                        xs12
                        md6
                        v-for="(value, itemKey) in settingData[struct.name]"
                        :key="itemKey"
                      >
                        <v-text-field
                          :label="struct.nameFormatter(itemKey)"
                          v-model="settingData[struct.name][itemKey]"
                        />
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-flex>
          </v-layout>
        </template>

        <v-layout v-else>
          <v-textarea label="Wert" v-model="settingData" />
        </v-layout>
      </v-card-text>
    </template>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="saveData" color="primary">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import settingsApi from '@/api/settingsApi'
import ValidationErrors from '@/components/ValidationErrors'

const settingsFormatter = {
  defaultDocumentYear: (value) => value,
  BiConfig: (value) => JSON.stringify(value, null, 3),
  idoc_customizing: (value) => value,
}

const structureMap = {
  idoc_customizing: [
    {
      label: 'IDoc Buchungen aktiv',
      name: 'idoc_booking_active',
      type: 'toggle',
    },
    {
      label: 'Buchungskreis',
      name: 'bukrs',
      type: 'text',
    },
    { label: 'Bewegungsart', name: 'bewar', type: 'text' },
    {
      label: 'Profit Center',
      name: 'prctr',
      type: 'text',
    },

    { label: 'HKONT Vorauszahlungen', name: 'hkont_prepayment', type: 'text' },
    { label: 'HKONT Gewerbesteuer', name: 'hkont_tax', type: 'text' },
    { label: 'HKONT Zinsen', name: 'hkont_interest', type: 'text' },
    { label: 'Kostenrechnungskreis', name: 'kokrs', type: 'text' },
    { label: 'Kostenstelle', name: 'kostl', type: 'text' },
    { label: 'Schlüssel Zahlungssperre', name: 'zlspr', type: 'text' },
    { label: 'Zahlungsbedingungsschlüssel', name: 'zterm', type: 'text' },
    {
      label: 'RstIDs Gewerbesteuer Forderung',
      name: 'Forderung_GewSt',
      type: 'keyValue',
      nameFormatter: (name) => `Jahr ${name}`,
    },
    {
      label: 'RstIDs Gewerbesteuer Rückstellung',
      name: 'Rueckstellung_GewSt',
      type: 'keyValue',
      nameFormatter: (name) => `Jahr ${name}`,
    },
    {
      label: 'RstIDs Zinsen Forderung',
      name: 'Forderung_Zinsen',
      type: 'keyValue',
      nameFormatter: (name) => `Jahr ${name}`,
    },
    {
      label: 'RstIDs Zinsen Rückstellung',
      name: 'Rueckstellung_Zinsen',
      type: 'keyValue',
      nameFormatter: (name) => `Jahr ${name}`,
    },
  ],
}

export default {
  name: 'Settings',
  components: { ValidationErrors },
  data() {
    return {
      headers: [
        { text: '', sortable: false },
        { text: 'Firmenname', value: 'name' },
        { text: 'Adresse', value: 'address' },
        { text: '', value: 'actions', align: 'right' },
      ],
      settingsId: null,
      companyId: null,
      settingData: null,
      validationErrors: null,
    }
  },
  computed: {
    ...mapGetters(['companies']),
    companySettings() {
      return [
        { value: null, text: 'Allgemeine Einstellungen' },
        ...this.companies.map((el) => ({
          value: el.id,
          text: el.name,
        })),
      ]
    },
    possibleSettings() {
      if (this.companyId) {
        return [
          {
            value: 'defaultDocumentYear',
            text: 'Voreingestelltes Veranlagungsjahr',
          },
          { value: 'BiConfig', text: 'Customizingdaten' },
          { value: 'idoc_customizing', text: 'IDoc Customizing' },
        ]
      } else {
        return [{ value: 'BiConfig', text: 'Customizing neue Firmen' }]
      }
    },
    requestData() {
      return {
        companyId: this.companyId,
        settingsId: this.settingsId,
      }
    },
    structureMap() {
      return structureMap[this.settingsId]
    },
  },
  methods: {
    async getCompanies() {
      try {
        this.loading = true
        await this.$store.dispatch('fetchCompanies')
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    async loadData() {
      this.settingData = null
      if (this.settingsId) {
        const { value } = await settingsApi.getEntry(
          this.companyId,
          this.settingsId
        )
        this.settingData = settingsFormatter[this.settingsId](value)
      }
    },
    async saveData() {
      try {
        await settingsApi.updateEntry(
          this.companyId,
          this.settingsId,
          JSON.stringify(this.settingData)
        )
        this.$root.setSnackbar('success', 'Erfolgreich gespeichert')
      } catch (e) {
        this.validationErrors = e.errorObject
      }
    },
  },
  watch: {
    requestData: 'loadData',
  },
}
</script>

<style scoped></style>
